import httpClient  from '../api'; 
const API_CONTROLLER = 'store-product/'; 


export default { 
    getProductAvailable(search) { 
        let url = API_CONTROLLER + 'get-product-available-in-store';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                storeId: search.storeId,
                categoryId: search.categoryId,
                keyword: search.keyword
            }
        });
    },
}